import * as React from 'react'

function SvgClockIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={47} height={47} viewBox="0 0 47 47" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.415 23.433a22.917 22.917 0 11-45.833 0 22.917 22.917 0 0145.833 0zM23.5 10.542a1.432 1.432 0 10-2.865 0v15.755a1.433 1.433 0 00.722 1.243l10.026 5.73a1.432 1.432 0 001.42-2.487L23.5 25.466V10.542z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgClockIcon
