import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FeatureList = styled.div`
  ${tw`
    flex 
    items-center
    space-x-3
  `}
`

export const FeatureIcon = styled.div`
  ${tw`
    flex
    items-center
    justify-center
    h-4
    w-4
    rounded-full
    bg-blue-700
  `}
  &.clock {
    background-color: #e9ffc3;
  }
  &.check {
    ${tw`
    bg-blue-700
    `}
  }
  svg {
    ${tw`
      h-2
      w-3
    `}
  }
`

export const FeatureListText = styled.div`
  p {
    ${tw`
      text-lg
      leading-6
      my-1
      font-medium
    `}
  }
  &.clock {
    color: #14d662;
  }
  &.check {
    ${tw`
      text-purple-100
    `}
  }
`
