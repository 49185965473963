import React from 'react'
import { ChildrenContent, Grid, Paragraph, Title, FeatureListCol, FeatureListRow } from './style'
import SvgCheckIcon from '../svgs/icons/check_icon'
import { RichText } from 'prismic-reactjs'
import FeatureWithIcon from '../features/feature_list/index'
import CallToAction from '../elements/call_to_action'

const SectionH2Base = ({ data, children, onlyWithChecks, callToAction, identifier }: any) => {
  return (
    <Grid>
      <ChildrenContent>{children}</ChildrenContent>
      <div>
        <Title>
          <p>{data.section_H2_base_primary.subhead}</p>
          <h2>{data.section_H2_base_primary.title}</h2>
        </Title>
        <Paragraph>
          <div>{RichText.render(data.section_H2_base_primary.description.raw)}</div>
          {onlyWithChecks ? (
            <FeatureListCol>
              {data.items.map(
                (item: { value_proposition_description: string }, index: string | number) => {
                  return (
                    <FeatureWithIcon
                      key={index}
                      type="check"
                      text={item.value_proposition_description}
                    >
                      <SvgCheckIcon color="#29E0E9" />
                    </FeatureWithIcon>
                  )
                },
              )}
            </FeatureListCol>
          ) : (
            <>
              <FeatureListRow>
                {data.items
                  .map(
                    (item: { value_proposition_description: string }, index: string | number) => {
                      return (
                        <FeatureWithIcon
                          key={index}
                          type="clock"
                          text={item.value_proposition_description}
                        />
                      )
                    },
                  )
                  .slice(0, 3)}
              </FeatureListRow>
              <FeatureListCol>
                <span>Thanks to...</span>
                {data.items
                  .map(
                    (item: { value_proposition_description: string }, index: string | number) => {
                      return (
                        <FeatureWithIcon
                          key={index}
                          type="check"
                          text={item.value_proposition_description}
                        />
                      )
                    },
                  )
                  .slice(3)}
              </FeatureListCol>
            </>
          )}
        </Paragraph>
        {callToAction && (
          <CallToAction>
            <a
              target="_blank"
              href={data.section_H2_base_primary.cta_path}
              className={`color ${identifier}`}
            >
              {data.section_H2_base_primary.cta}
            </a>
          </CallToAction>
        )}
      </div>
    </Grid>
  )
}

export default SectionH2Base
