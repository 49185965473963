import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Title = styled.div`
  ${tw`
    mx-auto
    text-base
    lg:max-w-none
  `}
  p {
    ${tw`
      leading-6
      text-blue-500
      font-semibold
      tracking-wide
    `}
    font-size: 1.25rem;
  }
  h2 {
    ${tw`
      my-6
      text-3xl
      font-extrabold
      tracking-tight
      text-purple-100
      sm:text-4xl
      leading-tight
      md:leading-normal
    `}
    @media (min-width: 640px) {
      font-size: 2.75rem;
    }
  }
`

export const Grid = styled.div`
  ${tw`
    lg:grid
    lg:grid-cols-2
    lg:gap-8
  `}
`

// export const SeeMoreLink = styled.div`
//   ${tw`
//     inline-flex
//   `}
//   a {
//     ${tw`
//       uppercase
//       text-base
//       leading-6
//       font-medium
//       transition
//       ease-in-out
//       duration-150
//     `}
//   &.home {
//     ${tw`
//       text-teal-500
//       hover:text-teal-300
//     `}
//   }
// `

export const ChildrenContent = styled.div`
  ${tw`
    relative
    mb-8
    sm:mb-40
    lg:mb-8
    lg:row-start-1
    lg:col-start-2
  `}
  @media (min-width: 768px) {
    height: 26rem;
  }
  @media (min-width: 1400px) {
    ${tw`
      h-full
      mb-0
    `}
  }
`

export const Paragraph = styled.div`
  ${tw`
    leading-loose
    mx-auto
    lg:max-w-none
    lg:row-start-1
    lg:col-start-1
    text-gray-600
  `}
  li {
    ${tw`
      list-decimal
      ml-5
    `}
  }
  span {
    ${tw`
      italic
      text-lg
    `}
  }
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.015rem;
`

export const FeatureListCol = styled.div`
  ${tw`
    my-8
  `}
`

export const FeatureListRow = styled.div`
  ${tw`
    mt-8
    mb-10
    flex
    flex-col
    md:flex-row
    md:space-x-6
  `}
`
