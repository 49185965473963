import React from 'react'
import SvgCheckIcon from '../../svgs/icons/check_icon'
import SvgClockIcon from '../../svgs/icons/clock_icon'
import { FeatureIcon, FeatureList, FeatureListText } from './style'

const FeatureWithIcon = ({ text, type }: any) => {
  return (
    <FeatureList>
      <FeatureIcon className={type}>
        {type === 'check' ? <SvgCheckIcon color="#29E0E9" /> : <SvgClockIcon color="#1FC763" />}
      </FeatureIcon>
      <FeatureListText className={type}>
        <p>{text}</p>
      </FeatureListText>
    </FeatureList>
  )
}

export default FeatureWithIcon
